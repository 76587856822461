//import { createRoot } from 'react-dom/client';
//import CountryDocs from '../elements/elCountryDocs';
/* import DocumentID from '../elements/elDocumentID';
import DropDown from '../elements/elDropDown';
import Date from '../elements/elDate';
import Email from '../elements/elEmail'; */
import GeolocationMap from '../elements/elGeolocationMap';
/* import Legal from '../elements/elLegal';
import LongText from '../elements/elLongText';
import MultipleChoice from '../elements/elMultipleChoice';
import Name from '../elements/elName';
import NumberInput from '../elements/elNumber';
import Selfie from '../elements/elSelfie';
import ShortText from '../elements/elShortText';
import Passport from '../elements/elPassport';
import Phone from '../elements/elPhone';
import ThankYou from '../elements/elThankYou';
import Welcome from '../elements/elWelcome'; */

const FnConsumeElements = (token, data) => {
    //const navRoot = createRoot(document.getElementById('question')); 
     // elCountryDocs = CountryDocs(data),
      //var  elDocumentID = DocumentID(data, "front"),
        /* elDropDown = DropDown(data),
        elDate = Date(data),
        elEmail = Email(data), */
        var element = "",
            elGeolocationMap = GeolocationMap(data);
        /* elLegal = Legal(data),
        elLongText = LongText(data),
        elMultipleChoice = MultipleChoice(data),
        elName = Name(data),
        elNumber = NumberInput(data),
        elSelfie = Selfie(data),
        elShortText = ShortText(data),
        elPassport = Passport(data),
        elPhone = Phone(data),
        elThankYou = ThankYou(data),
        elWelcome = Welcome(data); */
    
    switch(data.qstType){
        case "br":
            //navRoot.render(elDate);
            break;
        /* case "cd":
            navRoot.render(elCountryDocs);
            break; */
        case "dd":
            //navRoot.render(elDropDown);
            break;
        case "em":
            //navRoot.render(elEmail);
            break;
        case "gm":
            element = elGeolocationMap;
            break;
        case "is":
            //navRoot.render(elDocumentID);
            break;
        case "nt":
            //navRoot.render(elName);
            break;
        case "lg":
            //navRoot.render(elLegal);
            break;
        case "lt":
            //navRoot.render(elLongText);
            break;
        case "mc":
            //navRoot.render(elMultipleChoice);
            break;
        case "nm":
            //navRoot.render(elNumber);
            break;
        case "sl":
            //navRoot.render(elSelfie);
            break;
        case "st":
            //navRoot.render(elShortText);
            break;
        case "ps":
            //navRoot.render(elPassport);
            break;
        case "ph":
            //navRoot.render(elPhone);
            break;
        case "tk":
            //navRoot.render(elThankYou);
            break;
        case "ws":
            //navRoot.render(elWelcome);
            break;
        default:
            //navRoot.render("");
            break;
    }

    return element;
}

export default FnConsumeElements;