import FnConsumeElements from '../functions/fnConsumeElements';

const SearchElement = (token, form, idLogic) => {
    let dataForm = new FormData();
	dataForm.append("token", token);
	dataForm.append("form", form);
	dataForm.append("idLogic", idLogic);

	let endPointApi = process.env.REACT_APP_BACKEND_DEV;
	if (document.domain === "go.moyoai.com")
		endPointApi = process.env.REACT_APP_BACKEND_PROD;

	const ftElement = fetch (
		"https://" + endPointApi + "/reactv2/endpoints/api.search.element.php",
		{
			method: "POST",
			body: dataForm
		}
	)
		.then(function (response) {
			return response.json();
		})
		.then((results) => {
			var element = "";
			switch(results["formType"]){
				case "score":
					// Score form (Advance or Vanishing)
					console.log("score");
					break;
				case "identity":
					// Search General
					element = FnConsumeElements(token, results);
					break;
				default:
					// Search General
					element = FnConsumeElements(token, results);
					break;
			}
			console.log(element);
			return element;
		})
		.catch(function (err) {
			console.error(err);
		});

	console.log(ftElement);
	return null;
}

export default SearchElement;