import React from "react";
import FnCreateAnswers from "../functions/fnCreateAnswer";
import SearchElement from "../api/apiSearchElement";

import "../assets/css/button.css";

function CmButton(data) {
    var stShow = "off";
    var stAlign = " text-left";
    var rdUrl = null;

    if (data.bx && data.bx !== "") stShow = "on";
    if (data.bxa && data.bxa !== "") stAlign = " " + data.bxa;
    if (data.qstType === "tk" && data.rd) rdUrl = data.rd;

    const nxtQuestion = () => {
        if (data.qstType !== "ws" && data.qstType !== "sm" && data.qstType !== "tk") {
            FnCreateAnswers(data);
        } else {
            SearchElement(data.token, data.formId, parseInt(data.qstOrdLogic) + 1);
        }
    };

    return (
        <div className={"mai-button" + stAlign} data-show={stShow}>
            <button
                className='btn btn-success'
                url={rdUrl}
                id={"bt" + data.ty}
                onClick={nxtQuestion}>
                {data.bx}
            </button>
        </div>
    );
}

export default CmButton;