import React from "react";
import { createRoot } from "react-dom/client";

import MapGoogle from "../components/cmMapGoogle";

import "../assets/css/button.css"

import CmTitle from "../components/cmTitle";
import IcoGeolocation from "../assets/icons/icGeolocation";
import CmDescription from "../components/cmDescription";

export default function GeolocationMap(data) {
	var vCmTitle = CmTitle(data);
	var vCmIcon = IcoGeolocation(data);
	var vCmDescription = CmDescription(data);
	var stAlign = " text-left";
	if (data.bxa && data.bxa !== "") stAlign = " " + data.bxa;

	const showMap = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(function (position) {
				var lat = position.coords.latitude;
				var lon = position.coords.longitude;
				var maps = <MapGoogle data={data} latitude={lat} longitude={lon} />;
				createRoot(document.getElementById('question')).render(maps);
			});
		} else {
			alert("Tu navegador no soporta Geolocalización, por favor actualizalo");
		}
	}

	var question = (
		<div className='mai-element' id={data.qstType}>
			{vCmTitle}
			{vCmIcon}
			{vCmDescription}
			<div className={"mai-button" + stAlign} data-show="on">
				<button
					className='btn btn-success'
					id={"bt" + data.ty}
					onClick={showMap}>
					{data.bx}
				</button>
			</div>
		</div>
	);
	return question;
}