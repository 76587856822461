import Cookies from "universal-cookie";
import SearchElement from "./apiSearchElement";
import apiCreateSessions from "./apiCreateSession";

const apiSaveAnswers = (data, answer) => {
	const setCookies = new Cookies();
	var session = setCookies.get("session");
    var dataForm = new FormData();
	let endPointApi = process.env.REACT_APP_BACKEND_DEV;
	if(session === undefined){
		if(data.qstType !== "ws" && data.qstType !== "sm" && data.qstType !== "tk"){
			apiCreateSessions(data);
			setTimeout(() => {
				session = setCookies.get("session");
				_SaveAnswers(data, session, answer);
			}, 2000);
		}
	}else{
		_SaveAnswers(data, session, answer);
	}

	function _SaveAnswers(data, session, answer){
		dataForm.append("token", data.token);
		dataForm.append("session", session);
		dataForm.append("idQuestion", data.qstId);
		dataForm.append("idForm", data.formId);
		dataForm.append("answer", answer);
	
		if (document.domain === "go.moyoai.com")
			endPointApi = process.env.REACT_APP_BACKEND_PROD;
	
		fetch(
			"https://" + endPointApi + "/reactv2/endpoints/api.save.answers.php",
			{
				method: "POST",
				body: dataForm
			}
		)
			.then(function (response) {
				return response.json();
			})
			.then((results) => {
				if(results === "200-ok"){
					SearchElement(data.token, data.formId, parseInt(data.qstOrdLogic) + 1);
				}
				return null;
			})
			.catch(function (err) {
				console.error(err);
			});
	}
	return null;
}

export default apiSaveAnswers;