function footer() {
	return (
		<div className='footer' id="footer">
			<div className='timer'></div>
			<div className='brand'>
				<p>
					powered by <span>moyoAI</span>
				</p>
			</div>
		</div>
	);
}

export default footer;
