import { useRouteError } from "react-router-dom";

import "../assets/css/error.css"

export default function ErrorPage() {
  const error = useRouteError();
  var errorMessage = "";

  switch(error.status){
    case 404:
      errorMessage = (
        <div className="errorMessage">
          <p>Lo sentimos, se ha producido un error</p>
          <p>
            <i>{error.status} - Pagina no encontrada</i>
          </p>
        </div>
      );
      break;
    case 500:
      errorMessage = (
        <div className="errorMessage">
          <p>Lo sentimos, se ha producido un error</p>
          <p>
            <i>{error.status} - Error de servidor</i>
          </p>
        </div>
      );
      break;
    default:
      errorMessage = (
        <div className="errorMessage">
          <i>Lo sentimos, se ha producido un error</i>
        </div>
      );
      break;
  }

  return (
    <div id="error-page">
      <div className="error-content">
        {errorMessage}
      </div>
    </div>
  );
}