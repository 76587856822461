import React from "react";

import "../assets/css/loader.css";

function Loader() {
    return (
        <div className='loader' id='loader'>
            <div className='loaderICON'></div>
        </div>
    );
}

export default Loader;