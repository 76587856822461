import * as ReactDOMClient from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AppHiddens from "./apps/appHiddens";
import AppToken from "./apps/appToken";
import Redirect from "./functions/fnRedirect";
import ErrorPage from "./errors/errPage";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/index.css";

const rootElement = document.getElementById("root");
const root = ReactDOMClient.createRoot(rootElement);

const router = createBrowserRouter([
	{
		path: "/:token/:hiddens",
		element: <AppHiddens />,
		errorElement: <ErrorPage />
	},
	{
		path: "/:token",
		element: <AppToken />,
		errorElement: <ErrorPage />
	},
	{
		path: "/",
		element: <Redirect url='https://www.moyoai.com' />,
		errorElement: <ErrorPage />
	}
]);

root.render(
	<RouterProvider router={router} />
);
