import apiSaveAnswers from "../api/apiSaveAnswers";
import validator from 'validator';

const FnCreateAnswers = (data) => {
    var answer = "",
        msgError = "";
        switch (data.qstType) {
            case "cd": // Country & Document Type
                let countrySelected = document.getElementById("selectCountries").value;
                let cards = document.getElementsByClassName("card-check");
                let valueID = "off";
                for (var i = 0; i < cards.length; i++) {
                    if (cards[i].getAttribute("dataactive") === "on")
                        valueID = cards[i].getAttribute("datatype");
                }
                if (countrySelected === "NUL") {
                    document.getElementById("errorMessage").innerHTML = "Seleccione el país de origen del documento";
                } else if (valueID === "off") {
                    document.getElementById("errorMessage").innerHTML = "Seleccione el tipo de documento";
                } else {
                    document.getElementById("errorMessage").innerHTML = "";
                    answer = countrySelected + ", " + valueID;
                }
                break;
            case "dd": // Drop Down - Select
                let dropDown = document.getElementById("gntSelect").value;
                if(dropDown === "0"){
                    msgError = "Selecciona una opción";
                    document.getElementById("errorMessage").innerHTML = msgError;
                }else{
                    answer = dropDown;
                }
                break;
            case "br": // Date - Birthday
                var date = document.getElementById("dateInput").getAttribute("refdate").split("-");
                answer = date[2] + "-" + date[1] + "-" + date[0];
                break;
            case "em": // Email - Input
                var email = document.getElementById("gntInput").value;
                if(email === ""){
                    msgError = "Ingresa el correo electrónico";
                    document.getElementById("errorMessage").innerHTML = msgError;
                }else if(!validator.isEmail(email)){
                    msgError = "Ingresa un email valido";
                    document.getElementById("errorMessage").innerHTML = msgError;
                }else{
                    answer = email;
                }
                break;
            case "gl": // Geolocalización - Message
                break;
            case "gm": // Geolocalización - Mapa
                break;
            case "is": // ID Document - Mask Cam
                answer = "IdDocumentOk";
                break;
            case "lg": // Legal - Message
                let lgN = document.getElementById("nTerm").style.display;
                if (lgN === "none") answer = "Acepto";
                else answer = "No Acepto";
                break;
            case "lt": // Long Text - Textarea
                let textArea = document.getElementById("gntTextArea").value;
                if(textArea !== ""){
                    answer = textArea;
                }else{
                    msgError = "Debes ingresar los datos requeridos";
                    document.getElementById("errorMessage").innerHTML = msgError;
                }
                break;
            case "mc": // Multiple Choice - Choices
                let mcCards = document.getElementsByClassName("chck");
                for (var j = 0; j < mcCards.length; j++) {
                    if(mcCards[j].classList.contains("swico")){
                        let parentElement = mcCards[j].parentElement;
                        let textElement = parentElement.querySelector(".chtx").innerHTML;
                        if(textElement === "Otro"){
                            let OtherValue = document.getElementById("othValue").value;
                            if(OtherValue === ""){
                                msgError = "Ingrese el otro elemento";
                            }else{
                                answer = "oth: "+ OtherValue;
                            }
                        }

                        if(msgError === ""){
                            if(answer === "")
                                answer = mcCards[j].getAttribute("id").substring(2);
                        }else{
                            document.getElementById("errorMessage").innerHTML = msgError;
                        }
                    }
                }
                break;
            case "mi": // Multiple Inputs
                break;
            case "nm": // Number - Input
                let numInpt = document.getElementById("gntInput").value;
                if(numInpt.length < data.ln){
                    msgError = "Ingrese por lo menos " + data.ln + " dígitos";
                }else if(numInpt.length > data.lx){
                    msgError = "No puede ingresar más de " + data.lx + " dígitos";
                }else {
                    answer = numInpt;
                }
                document.getElementById("errorMessage").innerHTML = msgError;
                break;
            case "nt": // Name - Inputs
                var name1 = document.getElementById("name1").value;
                var name2 = document.getElementById("name2").value;
                var name3 = document.getElementById("name3").value;

                if(name1 === "")
                    msgError = "Ingrese su nombre(s)";
                else if(name2 === "" && name3 === "")
                    msgError = "Ingrese por lo menos un apellido";

                if(msgError === ""){
                    if(name3 === "")
                        answer = name1 + "-" + name2;
                        else
                            answer = name1 + "-" + name2 + "-" + name3;
                }else{
                    document.getElementById("errorMessage").innerHTML = msgError;
                }
                break;
            case "os": // Opinion Scale
                break;
            case "pc": // Picture Choice
                break;
            case "ph": // Phone - Input
                let areaCode = document.getElementById("selAreaCode").value;
                let phoneInput = document.getElementById("gntInput").value;
                var phoneNum = phoneInput.replaceAll(/[.*+?^${}()|[\]\\]/g, "").replaceAll("-", "").replaceAll(" ","");

                if(phoneInput === ""){
                    msgError = "Ingrese el número telefónico";
                }else if(phoneNum.length < data.lx){
                    msgError = "El numero de teléfono debe contener " + data.lx + " dígitos";
                }else{
                    answer = areaCode + "+ " + phoneNum;
                }
                document.getElementById("errorMessage").innerHTML = msgError;
                break;
            case "ps": // Passport - Mask Cam
                answer = "PassportOk";
                break;
            case "sg": // Signature - Canvas
                break;
            case "sl": // Selfie - Mask Cam
                answer = "SelfieOk";
                break;
            case "sm": // Statement - Message
                break;
            case "st": // Short Text - Input
                let shortTxt = document.getElementById("gntInput").value.replaceAll(/[.*+#?^%&$@{}()|[\]\\]/g, "").replaceAll("/", "");
                document.getElementById("gntInput").value = shortTxt;
                if(shortTxt.length < data.ln){
                    msgError = "Ingrese por lo menos " + data.ln + " caracteres";
                }else if(shortTxt.length > data.lx){
                    msgError = "No puede ingresar más de " + data.lx + " caracteres";
                }else {
                    answer = shortTxt;
                }
                document.getElementById("errorMessage").innerHTML = msgError;
                break;
            case "tk": // Thankyou Page
                break;
            case "ws": // Welcome
                break;
            case "yn": // Yes / No
                break;
            default:
                break;
        }
    
    if(msgError === ""){
        apiSaveAnswers(data, answer);
    }else{
        document.getElementById("errorMessage").style.display = "block";
    }
}

export default FnCreateAnswers;