import "../assets/css/messages.css";

function ErrorDevice(devType) {
	var messageDevice = null;

	switch (devType) {
		case "Private":
			messageDevice = (
				<div className='element' id='elmCnt'>
					<div className='messageDevice'>
						<h2>Acceso denegado</h2>
						<p>El formulario es privado</p>
					</div>
				</div>
			);
			break;
		case "PC":
			messageDevice = (
				<div className='element' id='elmCnt'>
					<div className='messageDevice'>
						<h2>Acceso denegado</h2>
						<p>
							No puede ingresar a esta evaluación desde una Tablet o Teléfono
							móvil, favor de copiar la URL y pegarla en su navegador desde una
							PC
						</p>
					</div>
				</div>
			);
			break;
		case "Mobile":
			messageDevice = (
				<div className='element' id='elmCnt'>
					<div className='messageDevice'>
						<h2>Acceso denegado</h2>
						<p>
							No puede ingresar a esta evaluación desde una PC, favor de
							escanear el código QR desde su dispositivo móvil
						</p>
					</div>
				</div>
			);
			break;
		default:
			messageDevice = (
				<div className='element' id='elmCnt'>
					<div className='messageDevice'>
						<h2>Acceso denegado</h2>
						<p>No estas autorizado para ingresar a esta evaluación</p>
					</div>
				</div>
			);
			break;
	}

	return messageDevice;
}

export default ErrorDevice;
