import React from "react";

function CmDescription(data) {
    var stShow = "off";
    var stAlign = "";
    var dsText = "";
    var idCount = 0;

    if (data.ds && data.ds !== "") {
        stShow = "on";
        if (data.dsa)
            stAlign = " " + data.dsa;
        dsText = data.ds.split("\n").map((i) => {
            idCount++;
            return (
                <p key={idCount}>{i}</p>
            );
        });
    }

    return (
        <div className={"mai-description" + stAlign} data-show={stShow}>
            {dsText}
        </div>
    );
}

export default CmDescription;