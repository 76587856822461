import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ScreenLandscape from "../components/cmScreenLandscape";
import footer from "../components/cmFooter";
import Loader from "../components/cmLoader";

import FnConsumeElements from '../functions/fnConsumeElements';
//import ApiAccessValidate from "../api/apiAccessValidate";

const AppToken = () => {
	var cmLoader = Loader();
	const urlParams = useParams();
	const [element, setElement] = useState(cmLoader);
	const [data, setData] = useState({});

	useEffect(() => {
		var token = urlParams.token;
		let dataForm = new FormData();
		dataForm.append("token", token);

		let endPointApi = process.env.REACT_APP_BACKEND_DEV;
		if (document.domain === "go.moyoai.com")
			endPointApi = process.env.REACT_APP_BACKEND_PROD;

		fetch(
			"https://" + endPointApi + "/reactv2/endpoints/api.test.php",
			{
				method: "POST",
				body: dataForm
			}
		)
			.then(function (response) {
				return response.json();
			})
			.then((results) => {
				setData(results);
			})
			.catch(function (err) {
				console.error(err);
			});
	}, [urlParams]);

	setTimeout(() => {
		setElement(FnConsumeElements(urlParams.token, data));
	}, 2000);

	return (
		<div className='App' id='App' lang='es'>
			<div className='wrapper'>
				<div className='question' id="question">
					{element}
				</div>
				{footer()}
			</div>
			{ScreenLandscape()}
		</div>
	);
}

export default AppToken;
