function screenRotate() {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 471.83 415.77'>
			<path d='M244.79,.17c-.8,.2-3.7,.7-6.3,1-2.7,.4-5.7,1.5-6.8,2.6-2.4,2.4-2.5,7-.1,9.9,1.8,2.2,2,2.2,16.7,1.3,42.5-2.5,75.1,5.9,108.1,27.8,15.3,10.2,34.4,29.3,44.7,44.9,17.5,26.4,26.3,53.3,28.2,86,1.1,18.6,1.3,18.5-14.7,3.1-14.8-14.3-17.2-15.5-21.6-10.8-4.5,4.8-3.7,6.1,17.9,27.4,11,10.9,20.9,20.2,22,20.7,1.2,.6,2.8,.5,4.3-.2,1.3-.6,9.6-10,18.5-20.8,17.1-21,18.3-23.2,14.1-27.4-1.2-1.2-3.3-2-5.5-2-3.1,0-4.2,.9-11.7,9.9l-8.3,9.9-.6-13.6c-1.2-23.5-6.9-46.1-17.4-68C401.69,50.57,354.49,13.97,298.19,2.57c-8.7-1.7-15.1-2.2-31.4-2.5-11.3-.1-21.2-.1-22,.1Z' />
			<path d='M108.59,80.47c-58.7,58.7-74.8,75.2-74.8,77.2s22.6,25,106.3,108.8c147.2,147.3,143,143.2,145.6,143.2s44.6-41.1,114.9-112.1c27.1-27.5,37.2-38.3,37.2-40,0-2.8-7.7-10.7-143.5-146.2C210.69,28.07,187.69,5.67,185.79,5.67c-2,0-18.5,16-77.2,74.8Zm176.7,43.2l99,99-66.8,66.7-66.7,66.8-99.2-99.2L52.29,157.67,118.79,91.17C155.39,54.57,185.49,24.67,185.79,24.67s45.1,44.6,99.5,99Zm67.5,200.5l-67,67-12.2-12.3-12.3-12.2,67-67,67-67,12.2,12.2,12.3,12.3-67,67Z' />
			<path d='M137.59,87.47c-12,12-13.8,14.2-13.8,17,0,4.2,3,7.2,7.3,7.2,2.8,0,4.9-1.7,16.9-13.8,7.7-7.6,14-14.8,14.3-16.2,1-4-2.7-8-7.3-8-3.3,0-4.7,1.1-17.4,13.8Z' />
			<path d='M107.49,118.47c-6.7,6.8-7.5,10.6-3.1,14.1,4.2,3.3,6.2,2.6,13.1-4.2,6.8-6.9,7.5-8.9,4.2-13.1-3.5-4.5-7.4-3.6-14.2,3.2Z' />
			<path d='M336.09,307.87c-11.4,11.3-13.3,13.6-13.3,16.3,0,3.9,3.6,7.5,7.5,7.5,2.3,0,5.2-2.3,14.7-11.8,15.5-15.2,17.1-18,12.3-22.7-4.6-4.7-6.6-3.7-21.2,10.7Z' />
			<path d='M17.19,221.17C8.39,231.87,.89,241.77,.39,243.07c-2,5.4,4,11,9.1,8.7,1.2-.6,5.7-5.3,10-10.5l7.8-9.4,.6,12.2c1.4,26.6,6.9,47.6,19,72.1,22.7,45.9,63.4,79.4,113.6,93.5,15.1,4.3,29.1,6.1,47.1,6.1,19,0,29.9-1.4,32.6-4.1,2.6-2.5,2.5-7.6-.2-10.2-2-2.1-2.4-2.1-16.9-.9-30.7,2.5-59.7-2.8-86.8-15.9-39.8-19.2-70.5-53.9-84.9-95.8-5.7-16.5-7.7-27.5-8.3-46.7l-.6-17,8.9,8.2c4.9,4.5,11.1,10.4,13.9,13.2,3.9,4.1,5.6,5.1,8.2,5.1,4.1,0,7.3-3,7.3-7,0-2.5-3-5.9-20.2-22.9-18.8-18.6-20.6-20.1-23.9-20.1-3.6,0-4.2,.6-19.5,19.5Z' />
		</svg>
	);
}

export default screenRotate;
