import React from "react";

function CmTitle(data) {
    var alignTI = " text-left";
    var numberQuestion = "";
    if (data.tia) alignTI = " " + data.tia;
    if (data.nq && data.nq !== 0)
        numberQuestion = <span className='mai-number-title'>{data.nq}. </span>;

    return (
        <div className={"mai-title" + alignTI}>
            <h2>
                {numberQuestion}
                <span className='mai-title-text'>{data.ti}</span>
            </h2>
        </div>
    );
}

export default CmTitle;