import Cookies from "universal-cookie";

const apiCreateSessions = ((data) => {
	let userAgent = navigator.platform,
		macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
		windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
		androidPlatforms = ["Linux armv8l", "Linux armv7l"],
		linuxPlatforms = ["Linux x86_64"],
		iPadPlatforms = ["iPad", "iPod"],
		iphonePlatforms = ["iPhone"],
		os = null;

	if (macosPlatforms.indexOf(userAgent) !== -1) {
		os = "Mac OS";
	} else if (windowsPlatforms.indexOf(userAgent) !== -1) {
		os = "Windows";
	} else if (androidPlatforms.indexOf(userAgent) !== -1) {
		os = "Android";
	} else if (linuxPlatforms.indexOf(userAgent) !== -1) {
		os = "Linux";
	} else if (iPadPlatforms.indexOf(userAgent) !== -1) {
		os = "Ipad";
	} else if (iphonePlatforms.indexOf(userAgent) !== -1) {
		os = "iphone";
	}

	var browserInfo = {
		appName: navigator.platform,
		appVersion: navigator.appVersion,
		userAgent: navigator.userAgent,
		language: navigator.language,
		platform: os,
		cookieEnabled: navigator.cookieEnabled
	};

	var dataForm = new FormData();
	let endPointApi = process.env.REACT_APP_BACKEND_DEV;

	dataForm.append("token", data.token);
	dataForm.append("browser", JSON.stringify(browserInfo));

	if (document.domain === "go.moyoai.com")
		endPointApi = process.env.REACT_APP_BACKEND_PROD;

	fetch("https://" + endPointApi + "/reactv2/endpoints/api.create.session.php", {
		method: "POST",
		body: dataForm
	})
		.then(function (response) {
			return response.json();
		})
		.then((results) => {
			let currentDate = new Date();
			let minutesAdd = results["ts"];

			if (minutesAdd !== 0) {
				currentDate.setMinutes(currentDate.getMinutes() + parseInt(minutesAdd));
			}

			const setCookies = new Cookies();
			setCookies.set("session", results["ss"], {
				path: "/",
				expires: currentDate,
				sameSite: "none",
				secure: true
			});

			return null;
		})
		.catch(function (err) {
			console.error(err);
		});
});

export default apiCreateSessions;
