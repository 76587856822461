import screenRotate from "../assets/icons/screenRotate.js";

const ScreenLandscape = () => {
    return (
        <div className='screenLandscape'>
            <h2>Gira tu dispositivo para tener una mejor experiencia!</h2>
            {screenRotate()}
        </div>
    )
}

export default ScreenLandscape;