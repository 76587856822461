import React from 'react';

import ErrorDevice from "../errors/errDevice";
import ScreenLandscape from "../components/cmScreenLandscape";

//import footer from "../components/cmFooter";

const AppHiddens = (props) => {
	var appElement = "";

	if(props.device.access === 0){
		const errorDev = ErrorDevice(props.device.device);
		appElement = errorDev ;
	}else{
		appElement = (
			<div>Formulario con hiddenfields</div>
		);
	}

	return (
		<div className='App'>
			<div className='wrapper'>
				<div className='content'>
					<div className="element" id='elmCnt'>
						{appElement}
					</div>
				</div>
			</div>
			{ScreenLandscape()}
		</div>
	);
}

export default AppHiddens;