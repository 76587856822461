import React from "react";
import "../../assets/css/icon.css";

const IcoGeolocation = (data) => {
	var showIM = "on";
	var alignIM = " text-center";
	if (data.ima) alignIM = " " + data.ima;
	
	var ico = (
		<div
			className={"gnt-icon-section" + alignIM}
			data-show={showIM}
			id='idImage'>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 740 740">
				<path fill="#888888" d="M370,41.111c-89.404,0-161.875,72.471-161.875,161.875c0,72.278,105.797,204.014,146.33,251.831
				c8.209,9.687,22.894,9.687,31.09,0c40.533-47.817,146.33-179.553,146.33-251.831C531.875,113.582,459.404,41.111,370,41.111z
				M370,256.944c-29.806,0-53.958-24.153-53.958-53.958c0-29.806,24.152-53.958,53.958-53.958s53.958,24.153,53.958,53.958
				C423.958,232.792,399.806,256.944,370,256.944z M25.849,318.547C10.24,324.79,0.004,339.905,0,356.716v321.592
				c0,14.543,14.685,24.487,28.187,19.091l177.369-80.731V317.224c-11.357-20.53-20.646-40.52-27.3-59.637L25.849,318.547z
				M370,503.188c-18.076,0-35.176-7.939-46.905-21.789c-25.258-29.806-52.121-63.748-76.428-98.564v233.82l246.667,82.222V382.848
				c-24.307,34.803-51.157,68.758-76.428,98.563C405.176,495.247,388.076,503.188,370,503.188z M711.813,248.157l-177.369,80.732v370
				l179.707-71.88c15.61-6.24,25.849-21.357,25.849-38.169V267.248C740,252.705,725.315,242.761,711.813,248.157z"/>
			</svg>
		</div>
	);

	return ico;
};

export default IcoGeolocation;
