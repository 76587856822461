import React, { useEffect } from "react";

const Redirect = ( url ) => {
   useEffect(() => {
      window.location.href = url.url;
   }, [url]);

   return (
      <div id="error-page">
         <div className="error-content">
            <div className="errorMessage">
               <p>Acceso denegado</p>
               <p>
                  <i>Estas siendo redirigido a moyoAI.com</i>
               </p>
            </div>
         </div>
      </div>
   );
};

export default Redirect;