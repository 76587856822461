import React, { Fragment } from "react";
import GoogleMapReact from 'google-map-react';
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";

import CmTitle from "../components/cmTitle";
import CmButton from "../components/cmButton";
import IcoMarker from "../assets/icons/icMarker";
import "../assets/css/mapGoogle.css";
 
export default class MapGoogle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        mapCenter: {
            lat: this.props.latitude,
            lng: this.props.longitude
        },
        address: ''
    };
  }

    componentDidMount() {
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY_DEV);
        Geocode.fromLatLng(this.state.mapCenter.lat, this.state.mapCenter.lng).then(
            (response) => {
                const nameAddress = response.results[0].formatted_address;
                this.setState({ address: nameAddress });
            },
            (error) => {
                console.error(error);
            }
        );
    }

    handleChange = (address) => {
		this.setState({ address });
	};

	handleSelect = (address) => {
        const nameAddress = address.formatted_address;
        Geocode.fromAddress(nameAddress).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                this.setState({ address: nameAddress });
                this.setState( {
                    mapCenter: {
                        lat: lat,
                        lng: lng
                    }
                })
            },
            (error) => {
              console.error(error);
            }
          );
	};

    fnChangeAddress = () => {
		let textAddress = document.getElementById("cntGoogleAddress").querySelector("input").value;
		let textTitleAddress = document.getElementById("chAddressText");
		textTitleAddress.innerHTML = textAddress;
		let chAddress = document.getElementById("chAddress");
		chAddress.style.display = "block";
	};

    fnChangeAddressCont = () => {
		let chAddress = document.getElementById("chAddress");
		chAddress.style.display = "none";
	};

    render() {
        var vCmTitle = CmTitle(this.props.data);
        var vCmButton = CmButton(this.props.data);
        const defaultProps = {
            center: {
                lat: this.props.latitude,
                lng: this.props.longitude
            },
            zoom: 17
        };

        const onMarkerDragEnd = (evt) => {
            let lat = evt.center.lat();
            let lng = evt.center.lng();

            this.setState( {
                mapCenter: {
                    lat: lat,
                    lng: lng
                }
            })

            Geocode.fromLatLng(
                lat,
                lng
            ).then(
                (response) => {
                    const nameAddress = response.results[0].formatted_address;
                    document.getElementById("cntGoogleAddress").querySelector("input").value = nameAddress;
                },
                (error) => {
                    console.error(error);
                }
            );
        }

        return (
            <Fragment>
                <div className='changeAddress' id='chAddress'>
					<h2 id='chAddressText'>{this.state.address}</h2>
					<div className='chaInput'>
						<p>No. Exterior</p>
						<input
							name='numExt'
							id='numExt'
							placeholder='Ingrese el número exterior correcto'
							defaultValue={this.state.nmExt}
						/>
					</div>
					<div className='chaInput'>
						<p>No. Interior</p>
						<input
							name='numInt'
							id='numInt'
							placeholder='Ingrese el número interior correcto'
							defaultValue={this.state.nmInt}
						/>
					</div>
					<button
						className='btn btn-success'
						onClick={this.fnChangeAddressCont}>
						Continuar
					</button>
				</div>
                <div className="mai-geolocation">
                    {vCmTitle}
                    <div className="mai-description">
                        <p>Mueva el pin a la ubicación correcta</p>
                    </div>

                    <div className='gnt-mapGoogle-address text-center'>
                        <div className='gnt-mapGoogle-address-control' id='cntGoogleAddress'>
                            <Autocomplete
                                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY_DEV}
                                style={{ width: "70%" }}
                                onPlaceSelected={(place) => {
                                    this.handleSelect(place);
                                }}
                                options={{
                                    types: ['address'],
                                }}
                                defaultValue={this.state.address}
                            />
                            <button className='btn btn-success' onClick={this.fnChangeAddress}>Detalle</button>
                        </div>
                    </div>

                    <div style={{ height: '65%', width: '100%', margin: '2rem 0px 1rem', position: 'relative' }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY_DEV, libraries: ['places'] }}
                            center={this.state.mapCenter}
                            defaultZoom={defaultProps.zoom}
                            onDragEnd={(e) => onMarkerDragEnd(e)}
                        ></GoogleMapReact>
                        <i className='iconGeo'>{IcoMarker()}</i>
                    </div>
                    {vCmButton}
                </div>
            </Fragment>
        );
    }
}